.cm-text-info {
    position: relative;
    height: calc(100% - 68px);
    margin: 0 auto;
    padding: 34px 30px;
    overflow-y: auto;
    color: #fff;
    background: #17313b;
    box-shadow: 0 7px 5px 1px rgba(0, 0, 0, .03), 0 22px 18px 1px rgba(0, 0, 0, .05), 0 100px 80px 1px rgba(0, 0, 0, .07);
}

.cm-text-info h2 {
    font-size: 24px;
    line-height: 28px;
    padding-top: 4px;
    padding-bottom: 18px;
    font-weight: 600;
    color: #fff;
    border-bottom: 1px solid hsla(0, 0%, 100%, .1);
}

.cm-text-info h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.cm-text-info div p a {
    display: inline-block;
    margin-top: 20px;
    color: #fb5092;
    cursor: pointer;
    text-decoration: underline;
}