.navbar-button {
    display: inline-block;
    background-color: white;
    border: none;
    color: #c0bec3;
    font-size: 14px;
    padding: 5px 20px;
    cursor: pointer;
    line-height: 20px;
    border-radius: 33px;
    list-style: none;
    margin-right: 20px;
}

.navbar-button.selected {
    background-color: red;
    color: #FFFFFF;
}
