.detail-container {
    background: #f8f8f8;
}

.detail-text {
    margin: 0 16px;
    font-size: 15px;
    line-height: 24px;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.detail-text img {
    max-width: 100%;
    height: auto;
}