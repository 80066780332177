.content-card2.oversized {
    height: 280px;
    flex-wrap: wrap;
}

a, a:active, a:hover, a:link, a:visited {
    text-decoration: none;
}

.content-card2 {
    display: flex;
    width: 100%;
    height: 120px;
    border-bottom: 2px solid #f4f4f4;
}

.content-card2.oversized .card-icon2 {
    width: 100%;
    height: calc(50vw - 16px);
}

.content-card2 .card-icon2 {
    display: flex;
    width: 170px;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    margin: 15px 15px 0;
}

.content-card2.oversized .card-main2 {
    width: 100%;
    margin: 0;
    padding-left: 27px;
    padding-top: 5px;
}

.content-card2 .card-main2 {
    width: calc(100% - 170px);
    /*padding-left: 12px;*/
    /*padding-top: 5px;*/
    margin-bottom: 15px;
}

.content-card2 .card-main2 .card-title2 {
    height: 48px;
    line-height: 24px;
    overflow: hidden;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-top: 0;
}

.content-card2 .card-main2 .card-info2 {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin: 0 15px 15px;

}

.content-card2.oversized .card-main2 .card-info2 {
    text-align: right;
}

.content-card2 .card-main2 .card-info2 .ads-flag2 {
    color: #999;
    font-size: 12px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}