.text-info {
    position: relative;
    display: block;
    width: 100%;
    height: 80%;
    margin: 0 auto;
    background: #17313b;
    color: #fff;
    box-shadow: 0 7px 5px 1px rgba(0, 0, 0, .03), 0 22px 18px 1px rgba(0, 0, 0, .05), 0 100px 80px 1px rgba(0, 0, 0, .07);
}

.pp-text-content {
    padding: 34px 30px;
}

.pp-text-content h2 {
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    padding-top: 4px;
    padding-bottom: 18px;
    border-bottom: 1px solid hsla(0, 0%, 100%, .1);
}

.pp-text-content a {
    color: #fb5092;
    cursor: pointer;
    text-decoration: underline;
}

.pp-text-content p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-size: 14px;
    color: #fff;
    line-height: 30px;
    margin-bottom: 34px;
}

.pp-text-content h3 {
    font-weight: 600;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
}

.pp-text-content ul {
    margin-bottom: 34px;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.pp-text-content ul li {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    display: list-item;
    text-align: -webkit-match-parent;
    list-style-type: disc;
}

.pp-text-content .info-table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 34px;
}

.pp-text-content .info-table table {
    table-layout: fixed;
    width: 100%;
    min-width: 1120px;
    border: 1px solid #ccc;
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    line-height: normal;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
    text-align: start;
    border-spacing: 2px;
    font-variant: normal;
    vertical-align: middle;
}


.pp-text-content .info-table table td {
    padding: 12px 20px;
    line-height: 24px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}