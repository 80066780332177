.text-header {
    width: 100%;
    height: 58px;
    position: relative;
    line-height: 30px;
    overflow: hidden;
}

.text-header h2 {
    width: calc(100% - 20px);
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    position: relative;
    padding-left: 8px;
    margin-left: 16px;
    margin-top: 14px;
}

.text-header h2:after {
    content: "";
    display: block;
    width: 4px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 6px;
    background-color: #ff2727;
}