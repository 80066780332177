a, a:active, a:hover, a:link, a:visited {
    text-decoration: none;
}

.content-card {
    display: flex;
    width: 100%;
    height: 120px;
    border-bottom: 2px solid #f4f4f4;
}

.card-icon {
    display: flex;
    width: 170px;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 0;
}

.card-icon img {
   width:170px;
}

.card-main {
    width: calc(100% - 170px);
    padding-left: 12px;
    padding-top: 5px;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.card-title {
    height: 48px;
    line-height: 24px;
    overflow: hidden;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-top: 0;
}

.card-info {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
